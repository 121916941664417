<template>
  <div class="flex-container non-scrollable">
    <page-title value="Gestion des jours fériés"/>
    <div class="scrollable">
      <DataTable ref="dtPublicHolidays" :value="publicHolidays" v-model:selection="selectedPublicHolidays" :paginator="true" :rows="constants.defaultRowNumber" v-model:filters="filters"
                 dataKey="id" :rowHover="true" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                 :rowsPerPageOptions="constants.rowsPerPageOptions" scrollable scroll-height="flex" :loading="loading" :rowClass="rowClass"
                 selection-mode="single"
                 :currentPageReportTemplate="$t('datatable.currentPageReportTemplate', {first: '{first}', last: '{last}', totalRecords: '{totalRecords}', target: $t('datatable.target.portes')})"
                  @row-click="openPublicHolidaysDialog">
        <template #header>
          <div class="flex flex-row justify-content-between">
            <Calendar v-model="yearFilter" selectionMode="multiple" :manualInput="false" :showIcon="true" showButtonBar
                      view="year" date-format="yy" @update:modelValue="yearFilterChange"/>
            <Button label="Ajouter jours fériés" icon="pi pi-plus" @click="openPublicHolidaysDialog" />
          </div>
        </template>
        <template #empty>
          {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
        </template>
        <template #loading>
          {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
        </template>
        <Column field="year" header="Année" sortable sortField="year"/>
        <Column header="Nombre de jours fériés">
          <template #body="{data}">
            {{ data.dates.length }}
          </template>
        </Column>
        <Column header="Jours fériés">
          <template #body="{data}">
            <Chip v-for="(dateObject, index) in data.dates" :key="index"
                  style="cursor: pointer" class="hover:bg-primary transition-duration-100 mr-1 mb-1"
                  @click="editPublicHolidayDate(dateObject, index, data.id)">
              <template #default>
                <div class="px-1 py-2">
                  <span>{{ formatPublicHolidayDate(dateObject.date) + ' ' }}</span>
                  <span class="font-bold">{{ dateObject.label }}</span>
                </div>
              </template>
            </Chip>
          </template>
        </Column>
      </DataTable>
      <Dialog v-if="!!publicHoliday" v-model:visible="publicHolidaysDialog" header="Édition jours fériés" :style="{width: '50%'}"
              :draggable="false" modal :closable="false" @hide="closePublicHolidaysDialog">
        <div class="flex flex-column gap-2">
          <div class="field">
            <label>Année des jours fériés</label>
            <Calendar v-model="publicHoliday.date" :manualInput="false" :showIcon="true"
                      view="year" date-format="yy" @update:modelValue="publicHolidayYearChange"/>
          </div>
          <div class="field">
            <label>Ensemble des jours fériés</label>
            <div class="flex flex-column">
              <div class="grid">
                <Chip v-for="(dateObject, index) in publicHoliday.dates" :key="index"
                      style="cursor: pointer" class="hover:bg-primary transition-duration-100 mr-1 mb-1"
                      removable @remove.stop="removePublicHolidayDate(dateObject, index)"
                      @click="editPublicHolidayDate(dateObject, index)">
                  <template #default>
                    <div class="px-1 py-2">
                      <span>{{ formatPublicHolidayDate(dateObject.date) + ' ' }}</span>
                      <span class="font-bold">{{ dateObject.label }}</span>
                    </div>
                  </template>
                </Chip>
              </div>
              <Button icon="pi pi-plus" size="small" rounded :disabled="!publicHoliday.date" @click="addPublicHolidayDates"/>
            </div>
          </div>
        </div>
        <template #footer>
          <Button label="Annuler" icon="pi pi-times" class="p-button-outlined" @click="closePublicHolidaysDialog" autofocus/>
          <Button v-if="publicHoliday.id" label="Supprimer" icon="pi pi-times" class="p-button-danger" @click="deletePublicHoliday" autofocus/>
          <Button label="Sauvegarder" icon="pi pi-check" @click="savePublicHoliday" autofocus/>
        </template>
      </Dialog>
      <Dialog v-if="!!publicHolidayDate" v-model:visible="publicHolidayDateDialog" header="Édition d'un jour férié" :style="{width: '50%'}"
              :draggable="false" modal :closable="false" @hide="closePublicHolidayDateDialog">
        <div class="flex flex-column gap-2">
          <div class="field">
            <label>Date du jour férié</label>
            <Calendar v-model="publicHolidayDate.date" :manualInput="false" :showIcon="true"
                      :minDate="publicHolidayDateMinDate" :maxDate="publicHolidayDateMaxDate" :disabled-dates="publicHolidayDateDisabledDates"
                      view="date" date-format="DD dd MM"/>
          </div>
          <div class="field">
            <label>Label</label>
            <InputText v-model="publicHolidayDate.label" type="text"/>
          </div>
        </div>
        <template #footer>
          <Button label="Annuler" icon="pi pi-times" class="p-button-outlined" @click="closePublicHolidayDateDialog" autofocus/>
<!--          <Button v-if="!isNaN(publicHolidayDate.index)" label="Supprimer" icon="pi pi-times" class="p-button-danger" @click="deletePublicHolidayDate" autofocus/>-->
          <Button label="Sauvegarder" icon="pi pi-check" @click="savePublicHolidayDate" autofocus/>
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Alert from "@/utils/Alert";
import PageTitle from "@/components/PageTitle.vue";
import {FilterMatchMode} from "primevue/api";
import {setDateWithoutHours} from "@/utils/Date";

export default {
  name: "PublicHolidays",
  components: {PageTitle},
  data () {
    return {
      filters: {
        'year': { value: null, matchMode: FilterMatchMode.IN },
      },
      loading: true,
      selectedPublicHolidays: null,

      publicHolidaysDialog: false,
      publicHoliday: null,

      publicHolidayDateDialog: false,
      publicHolidayDate: null,

      yearFilter: null,
    }
  },
  computed: {
    ...mapState({
      publicHolidays: state => state.publicHolidays.publicHolidays,
      constants: state => state.constants
    }),
    publicHolidayDateMinDate () {
      return new Date(this.publicHoliday.year, 0, 1)
    },
    publicHolidayDateMaxDate () {
      return new Date(this.publicHoliday.year, 11, new Date(this.publicHoliday.year + 1, 0, 0).getDate())
    },
    publicHolidayDateDisabledDates () {
      return this.publicHoliday.dates.map(publicHolidayDate => new Date(publicHolidayDate.date))
    }
  },
  async created () {
    try {
      this.loading = true
      await this.$store.dispatch('publicHolidays/getPublicHolidays', {})
      this.loading = false
    } catch (e) {
      Alert.errorMessage(this, 'publicHolidaysGetError')
      this.loading = false
    }
  },
  methods: {
    rowClass (data) {
      return [{ 'font-bold bg-gray-50': data.year === parseInt(setDateWithoutHours(new Date()).split('-')[0]) }];
    },
    async yearFilterChange (dates) {
      const yearFilterValues = dates?.length > 0 ? dates.map(date => new Date(date).getFullYear()) : null
      this.filters = {
        'year': { value: yearFilterValues, matchMode: FilterMatchMode.IN },
      }
    },
    formatPublicHolidayDate (date) {
      return `${(new Date(date)).toLocaleDateString('fr-FR', {
        day: '2-digit',
        month: 'short',
      })}`
    },
    closePublicHolidayDateDialog () {
      this.publicHolidayDateDialog = false
      this.resetPublicHolidayDate()
    },
    openPublicHolidayDateDialog (publicHolidayDate) {
      this.publicHolidayDate = publicHolidayDate
      this.publicHolidayDateDialog = true
    },
    resetPublicHolidayDate () {
      this.publicHolidayDate = null
    },
    savePublicHolidayDate () {
      const newPublicHolidayDate = { label: this.publicHolidayDate.label, date: setDateWithoutHours(this.publicHolidayDate.date) }
      if (isNaN(this.publicHolidayDate.index)) {
        this.publicHoliday.dates.push(newPublicHolidayDate)
      } else {
        this.publicHoliday.dates.splice(this.publicHolidayDate.index, 1, newPublicHolidayDate)
      }
      this.closePublicHolidayDateDialog()
    },
    deletePublicHolidayDate (index = null) {
      const indexToRemove = isNaN(index) ? this.publicHolidayDate.index : index
      if (isNaN(indexToRemove) || indexToRemove === -1 || !this.publicHoliday.dates[indexToRemove]) return
      this.publicHoliday.dates.splice(indexToRemove, 1)
      this.closePublicHolidayDateDialog()
    },
    closePublicHolidaysDialog () {
      this.publicHolidaysDialog = false
      this.resetPublicHoliday()
    },
    async openPublicHolidaysDialog (event) {
      if (event?.data?.id) {
        await this.setPublicHolidayObject(event?.data)
      } else {
        this.publicHoliday = {
          year: null,
          date: null,
          dates: [],
        }
      }
      this.publicHolidaysDialog = true
    },
    async publicHolidayYearChange (date) {
      const year = setDateWithoutHours(date).split('-')[0]
      await this.setPublicHolidayObjectByYear(year)
    },
    resetPublicHoliday () {
      this.publicHoliday = null
    },
    async setPublicHolidayObject (publicHoliday) {
      try {
        this.publicHoliday = await this.$store.dispatch('publicHolidays/getPublicHoliday', { id: publicHoliday.id })
        await this.setPublicHolidayDateFromYear(this.publicHoliday.year)
      } catch (e) {
        Alert.errorMessage(this, 'publicHolidayGetOneError')
      }
    },
    async setPublicHolidayObjectByYear (year) {
      try {
        this.publicHoliday = await this.$store.dispatch('publicHolidays/getPublicHolidayByYear', { year })
        await this.setPublicHolidayDateFromYear(year)
      } catch (e) {
        this.publicHoliday = {
          year,
          dates: []
        }
        await this.setPublicHolidayDateFromYear(year)
        //  Alert.errorMessage(this, 'publicHolidayGetOneError')
      }
    },
    async setPublicHolidayDateFromYear (year) {
      process.nextTick(() => {
        const date = new Date(year, 1, 2)
        this.publicHoliday.date = date
      })
    },
    async deletePublicHoliday () {
      this.$confirm.require({
        message: `Êtes-vous sûr de vouloir supprimer les jours fériés de l'année ${this.publicHoliday.year} ?`,
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        accept: async () => {
          try {
            await this.$store.dispatch('publicHolidays/deletePublicHoliday', this.publicHoliday)
            Alert.successMessage(this, 'publicHolidayDelete', { year: this.publicHoliday.year })
            this.closePublicHolidaysDialog()
          } catch (e) {
            Alert.errorMessage(this, 'publicHolidayDeleteError')
          }
        },
        reject: () => {}
      });
    },
    savePublicHoliday () {
      this.$confirm.require({
        message: `Êtes-vous sûr de vouloir sauvegarder les jours fériés de l'année ${this.publicHoliday.year} ?`,
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        accept: async () => {
          try {
            if (this.publicHoliday.id) {
              this.$store.dispatch('publicHolidays/updatePublicHoliday', this.publicHoliday)
              Alert.successMessage(this, 'publicHolidaySave', { year: this.publicHoliday.year })
            } else {
              this.$store.dispatch('publicHolidays/addPublicHolidays', {
                ...this.publicHoliday,
                year: parseInt(setDateWithoutHours(this.publicHoliday.date).split('-')[0])
              })
              Alert.successMessage(this, 'publicHolidayCreate', { year: this.publicHoliday.year })
            }
            this.closePublicHolidaysDialog()
          } catch (e) {
            Alert.errorMessage(this, 'publicHolidaySaveError')
          }
        },
        reject: () => {}
      });
    },
    removePublicHolidayDate (dateObject, index) {
      this.deletePublicHolidayDate(index)
    },
    async editPublicHolidayDate (dateObject, index, id = null) {
      if (!this.publicHoliday && !!id) await this.openPublicHolidaysDialog({ data: { id } })
      this.openPublicHolidayDateDialog({ ...dateObject, index, date: new Date(dateObject.date) })
    },
    addPublicHolidayDates () {
      const currentDate = new Date()
      this.openPublicHolidayDateDialog({ date: new Date(this.publicHoliday.year, currentDate.getMonth(), currentDate.getDate()) })
    }
  }
}
</script>

<style scoped>
</style>
